const transportModalityData = [
    {
        label: "Aéreo",
        value: "AEREO",
    },
    {
        label: "Marítimo",
        value: "MARITIMO",
    },
    {
        label: "Rodoviário",
        value: "RODOVIARIO",
    },
];

export default transportModalityData;